<template>
  <v-container fluid>
    <v-row no-gutters class="pb-4">
      <v-col cols="12" class="pr-2">
        <p class="text--secondary mb-2">Abrir painel de ações.</p>
        <v-btn color="primary" class="elevation-12" block @click="actionsPanelDialog = true"><v-icon class="mr-2 mb-1">mdi-cog</v-icon> Painel de Ações</v-btn>
      </v-col>
    </v-row>

    <!-- dialogs -->
    <deployment-actions-panel-dialog :commands="deploymentCommands" :dialog.sync="actionsPanelDialog" @cancel="actionsPanelDialog = false" />
  </v-container>
</template>

<script>
import { QUERY_GET_COMMANDS } from '@/modules/deployment/graphql'

export default {
  components: {
    DeploymentActionsPanelDialog: () => import('@/modules/deployment/components/DeploymentActionsPanelDialog')
  },
  data: () => ({
    actionsPanelDialog: false,
    importClientsDialog: false,
    deploymentCommands: []
  }),
  apollo: {
    adminGetDeploymentCommands: {
      query: QUERY_GET_COMMANDS,
      fetchPolicy: 'network-only',
      update({ adminGetDeploymentCommands }) {
        if (!adminGetDeploymentCommands) return []
        this.deploymentCommands = adminGetDeploymentCommands
      }
    }
  }
}
</script>
